// i18next-extract-mark-ns-start about-us-locations

import { ILocation, IOffice } from '@utils/types';
import { graphql } from 'gatsby';
import React from 'react';

import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import ContactTile from '@components/ContactTile';
import Deck from '@components/Deck';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const AboutPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection className="section__content" layout="wide">
				{data.locations.edges.map(({ node }: { node: ILocation }) => (
					<>
						<div className="contact__header">
							<h2 className="contact__title">{t(node.labelkey)}</h2>
						</div>
						<Deck columns={4}>
							{node.locations.map(
								(
									{
										title,
										address1,
										address2,
										address3,
										address4,
										address5,
										address6,
										telephone,
										email,
									}: IOffice,
									i: number
								) => (
									<ContactTile
										key={i}
										title={title}
										address1={address1}
										address2={address2}
										address3={address3}
										address4={address4}
										address5={address5}
										address6={address6}
										telephone={telephone}
										email={email}
									/>
								)
							)}
						</Deck>
					</>
				))}
			</PageSection>
		</Page>
	);
};

export default AboutPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["about-us-locations", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/about-us/locations/Realis-Simulation_Locations_cropped.jpg" }) {
		...imageBreaker
	}
	locations: allLocationsJson {
		edges {
			node {
				labelkey
				locations {
					title
					address1
					address2
					address3
					address4
					address5
					address6
					telephone
					email
				}
			}
		}
	}
}
`;