import { IOffice } from '@utils/types';
import React from 'react';

const ContactTile = ({
	title,
	address1,
	address2,
	address3,
	address4,
	address5,
	address6,
	telephone,
	email,
}: IOffice) => {
	return (
		<div className="contact-tile">
			<div className="contact-tile__content">
				<span className="contact-tile__name">{title}</span>
				<span className="contact-tile__address">{address1}</span>
				<span className="contact-tile__address">{address2}</span>
				{address3 && (
					<span className="contact-tile__address">{address3}</span>
				)}
				{address4 && (
					<span className="contact-tile__address">{address4}</span>
				)}
				{address5 && (
					<span className="contact-tile__address">{address5}</span>
				)}
				{address6 && (
					<span className="contact-tile__address">{address6}</span>
				)}
				{telephone && (
					<span className="contact-tile__address">
						T: {telephone}
					</span>
				)}
				{email && (
					<span className="contact-tile__address">E: {email}</span>
				)}
			</div>
		</div>
	);
};

export default ContactTile;
